/*
 * @NOTE: Prepend a `~` to css file paths that are in your node_modules
 *        See https://github.com/webpack-contrib/sass-loader#imports
 */


li:not(.webeditor li) {
  list-style: none;
}

.webeditor li{
  margin-left:10px;
}

main{
  width:100% !important;
}
a {
  text-decoration: none;
  height: fit-content;
  width: fit-content;
  margin: 10px;
}

a:hover {
  opacity: 1;
  text-decoration: none;
}


.width-manager .resize-handler{
  transition:0.2s;
  position:relative
}

.width-manager:hover .resize-handler{
  background-color:#666
}

.ql-align-center{
  text-align:center
}


*{
  -webkit-box-shadow: unset !important;
}

li::marker{
  margin-left:10px;
}